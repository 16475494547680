<template>
  <div class="list-user">
    <div
      style="
        box-shadow: 0 2px 10px rgb(0 0 0 / 20%);
        border-radius: revert;
        background: #fff;
      "
    >
      <div class="d-flex justify-content-between pt-4 pb-4 pl-4">
        <h4 class="font-title">お問い合わせ管理</h4>
      </div>
      <div>
        <div class="create-inquiry">
          <CRow>
            <CCol sm="12">
              <label class="cp-label">メールアドレス</label>
              <div class="d-flex">
                <CInput
                  class="w-50 cp-4 mb-0"
                  v-model="inquiry.email"
                  disabled
                />
              </div>
            </CCol>
            <CCol sm="12">
              <label class="cp-label">氏名</label>
              <div class="d-flex">
                <CInput
                  class="w-50 cp-4 mb-0"
                  v-model="inquiry.name"
                  disabled
                />
              </div>
            </CCol>
            <CCol sm="12">
              <label class="cp-label">お問い合わせ内容</label>
              <CTextarea
                class="cp-4 mb-0"
                v-model="inquiry.content"
                disabled
                rows="10"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="12">
              <div class="d-flex justify-content-center mb-4">
                <CButton
                  v-on:click.prevent="createForm()"
                  :color="'success'"
                  :size="'lg'"
                  class="mt-3 mr-3"
                  style="border-radius: 50% !important"
                >
                  <font-awesome-icon :icon="['fas', 'plus']" />
                </CButton>
              </div>
            </CCol>
          </CRow>
          <div v-for="(value, index) in form" :key="index">
            <CRow class="mb-3" v-if="form.length > 0">
              <CCol sm="12" v-if="value.type === 1">
                <label class="px-4">{{ value.form.label }}</label>
                <div class="d-flex cp-4">
                  <b-form-input
                    class="w-100 px-4"
                    v-model="value.form.value"
                  ></b-form-input>
                  <CButton
                    v-on:click.prevent="deleteElementForm(index)"
                    :color="'danger'"
                    :size="'sm'"
                    style="
                      height: calc(1.5em + 0.75rem + 2px);
                      margin-left: 10px;
                    "
                  >
                    <font-awesome-icon :icon="['fas', 'minus']" />
                  </CButton>
                </div>
              </CCol>
              <CCol sm="12" v-if="value.type === 2">
                <label class="px-4">{{ value.form.label }}</label>
                <div class="d-flex cp-4">
                  <CTextarea
                    class="w-100"
                    v-model="value.value"
                    rows="10"
                  ></CTextarea>
                  <CButton
                    v-on:click.prevent="deleteElementForm(index)"
                    :color="'danger'"
                    :size="'sm'"
                    style="
                      height: calc(1.5em + 0.75rem + 2px);
                      margin-left: 10px;
                    "
                  >
                    <font-awesome-icon :icon="['fas', 'minus']" />
                  </CButton>
                </div>
              </CCol>
              <CCol sm="12" v-if="value.type === 3">
                <label class="px-4">{{ value.form.label }}</label>
                <div class="d-flex cp-4">
                  <b-form-select
                    v-model="value.form.value"
                    :options="value.form.options"
                  ></b-form-select>
                  <CButton
                    v-on:click.prevent="deleteElementForm(index)"
                    :color="'danger'"
                    :size="'sm'"
                    style="
                      height: calc(1.5em + 0.75rem + 2px);
                      margin-left: 10px;
                    "
                  >
                    <font-awesome-icon :icon="['fas', 'minus']" />
                  </CButton>
                </div>
              </CCol>
              <CCol sm="12" v-if="value.type === 4">
                <label class="px-4">{{ value.form.label }}</label>
                <div class="d-flex px-4 align-items-center">
                  <b-form-radio-group
                    v-model="value.form.value"
                    :options="value.form.options"
                    :name="value.form.name"
                  ></b-form-radio-group>
                  <CButton
                    v-on:click.prevent="deleteElementForm(index)"
                    :color="'danger'"
                    :size="'sm'"
                    style="
                      height: calc(1.5em + 0.75rem + 2px);
                      margin-left: 10px;
                    "
                  >
                    <font-awesome-icon :icon="['fas', 'minus']" />
                  </CButton>
                </div>
              </CCol>
              <CCol sm="12" v-if="value.type === 5">
                <label class="px-4">{{ value.form.label }}</label>
                <div class="d-flex px-4 align-items-center">
                  <b-form-checkbox-group
                    v-model="value.form.value"
                    :options="value.form.options"
                    :name="value.form.name"
                  ></b-form-checkbox-group>
                  <CButton
                    v-on:click.prevent="deleteElementForm(index)"
                    :color="'danger'"
                    :size="'sm'"
                    style="
                      height: calc(1.5em + 0.75rem + 2px);
                      margin-left: 10px;
                    "
                  >
                    <font-awesome-icon :icon="['fas', 'minus']" />
                  </CButton>
                </div>
              </CCol>
            </CRow>
          </div>
          <CRow>
            <CCol sm="12">
              <div class="d-flex justify-content-center pb-4">
                <button
                  v-on:click.prevent="submit()"
                  :color="'success'"
                  :size="'lg'"
                  class="mt-3 mr-3 btn-save"
                >
                  編集内容を反映する
                </button>
                <CButton
                  v-on:click.prevent="goToListInquiry()"
                  :color="'secondary'"
                  :size="'lg'"
                  class="mt-3"
                  style="height: 42px"
                >
                  キャンセル
                </CButton>
              </div>
            </CCol>
          </CRow>
        </div>
      </div>
    </div>
    <!-- Modal Add Form -->
    <b-modal
      id="modal-add-form"
      size="lg"
      title="フォーム"
      hide-footer
      scrollable
    >
      <CRow class="mb-3">
        <CCol sm="12">
          <b-form-radio-group
            v-model="typeForm"
            :options="optionsTypeForm"
            button-variant="outline-primary"
          ></b-form-radio-group>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="12">
          <CInput
            label="項目名"
            v-if="typeForm === 1"
            v-model="formInput.label"
          ></CInput>
          <CInput
            label="項目名"
            v-if="typeForm === 2"
            v-model="formTextArea.label"
          ></CInput>
          <div v-if="typeForm === 3">
            <CInput label="項目名" v-model="formSelect.label"></CInput>
            <div
              class="d-flex"
              v-for="(value, index) in formSelect.options"
              :key="index"
            >
              <CInput
                class="w-100"
                label="テキスト"
                v-model="value.text"
              ></CInput>
              <CButton
                v-if="formSelect.options.length > 1"
                v-on:click.prevent="deleteOption(index, typeForm)"
                :color="'danger'"
                :size="'sm'"
                style="
                  margin-bottom: 1rem;
                  margin-left: 10px;
                  align-self: flex-end;
                "
              >
                <font-awesome-icon :icon="['fas', 'minus']" />
              </CButton>
              <CButton
                v-on:click.prevent="addOption(index, typeForm)"
                :color="'success'"
                :size="'sm'"
                style="
                  margin-bottom: 1rem;
                  margin-left: 10px;
                  align-self: flex-end;
                "
              >
                <font-awesome-icon :icon="['fas', 'plus']" />
              </CButton>
            </div>
          </div>
          <div v-if="typeForm === 4">
            <CInput label="項目名" v-model="formRadio.label"></CInput>
            <div
              class="d-flex"
              v-for="(value, index) in formRadio.options"
              :key="index"
            >
              <CInput
                class="w-100"
                label="テキスト"
                v-model="value.text"
              ></CInput>
              <CButton
                v-if="formRadio.options.length > 1"
                v-on:click.prevent="deleteOption(index, typeForm)"
                :color="'danger'"
                :size="'sm'"
                style="
                  margin-bottom: 1rem;
                  margin-left: 10px;
                  align-self: flex-end;
                "
              >
                <font-awesome-icon :icon="['fas', 'minus']" />
              </CButton>
              <CButton
                v-on:click.prevent="addOption(index, typeForm)"
                :color="'success'"
                :size="'sm'"
                style="
                  margin-bottom: 1rem;
                  margin-left: 10px;
                  align-self: flex-end;
                "
              >
                <font-awesome-icon :icon="['fas', 'plus']" />
              </CButton>
            </div>
          </div>
          <div v-if="typeForm === 5">
            <CInput label="項目名" v-model="formCheckbox.label"></CInput>
            <div
              class="d-flex"
              v-for="(value, index) in formCheckbox.options"
              :key="index"
            >
              <CInput
                class="w-100"
                label="テキスト"
                v-model="value.text"
              ></CInput>
              <CButton
                v-if="formCheckbox.options.length > 1"
                v-on:click.prevent="deleteOption(index, typeForm)"
                :color="'danger'"
                :size="'sm'"
                style="
                  margin-bottom: 1rem;
                  margin-left: 10px;
                  align-self: flex-end;
                "
              >
                <font-awesome-icon :icon="['fas', 'minus']" />
              </CButton>
              <CButton
                v-on:click.prevent="addOption(index, typeForm)"
                :color="'success'"
                :size="'sm'"
                style="
                  margin-bottom: 1rem;
                  margin-left: 10px;
                  align-self: flex-end;
                "
              >
                <font-awesome-icon :icon="['fas', 'plus']" />
              </CButton>
            </div>
          </div>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="12">
          <button @click="addForm()" class="btn-save">作成フォーム</button>
        </CCol>
      </CRow>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Constants } from "../../utils/constants";

export default {
  name: "CreateUser",

  data() {
    return {
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      form: [],
      formInput: {
        label: "",
        value: "",
      },
      formTextArea: {
        label: "",
        value: "",
      },
      formSelect: {
        label: "",
        options: [
          {
            value: "",
            text: "",
          },
        ],
        value: "",
      },
      formRadio: {
        label: "",
        options: [
          {
            value: "",
            text: "",
          },
        ],
        name: "",
        value: "",
      },
      formCheckbox: {
        label: "",
        options: [
          {
            value: "",
            text: "",
          },
        ],
        name: "",
        value: [],
      },
      typeForm: "",
      optionsTypeForm: [
        {
          text: "テキスト",
          value: 1,
        },
        {
          text: "テキストエリア",
          value: 2,
        },
        {
          text: "セレクトボックス",
          value: 3,
        },
        {
          text: "ラジオボタン",
          value: 4,
        },
        {
          text: "チェックボタン",
          value: 5,
        },
      ],
      option: "",
      inquiry: {
        no: "",
        id: this.$route.params.id,
        name: "",
        email: "",
        message: "",
        status: "",
      },
      optionsStatus: Constants.STATUS_INQUIRY,
    };
  },
  created() {
    this.getFormInquiry(this.shop_id);
  },
  computed: {
    ...mapGetters([
      "success",
      "message",
      "error",
      "listSlag",
      "userDetail",
      "formInquiry",
    ]),
  },
  watch: {
    success() {
      if (this.success) {
        this.loading = false;
        this.$toasted.clear();
        this.$toasted.success(this.message);
        this.$store.commit("set", ["success", false]);
        this.$store.commit("set", ["message", ""]);
      }
    },
    error() {
      if (this.error) {
        this.loading = false;
        this.$toasted.clear();
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    formInquiry() {
      if (this.formInquiry) {
        this.form = this.formInquiry.column;
      }
    },
  },
  methods: {
    ...mapActions({ createInquiry: "createInquiry" }),
    ...mapActions({ getUserById: "getUserById" }),
    ...mapActions({ getALLlistSlag: "getALLlistSlag" }),
    ...mapActions({ getShopByIdForAdmin: "getShopByIdForAdmin" }),
    ...mapActions({ createUserOwner: "createUserOwner" }),
    ...mapActions({ getFormInquiry: "getFormInquiry" }),
    addOption(index, type) {
      const form =
        type === 3
          ? this.formSelect
          : type === 4
          ? this.formRadio
          : this.formCheckbox;
      form.options.splice(index + 1, 0, {
        value: "",
        text: "",
      });
    },
    deleteOption(index, type) {
      const form =
        type === 3
          ? this.formSelect
          : type === 4
          ? this.formRadio
          : this.formCheckbox;
      form.options.splice(index, 1);
    },
    createForm() {
      this.$bvModal.show("modal-add-form");
    },
    addForm() {
      let error = false;
      let message = "";
      let form;
      switch (this.typeForm) {
        case 1:
          if (this.formInput.label === "") {
            error = true;
            message = "ラベルのフィールドは必須です。";
          } else {
            form = this.formInput;
            this.formInput = { label: "", value: "" };
          }
          break;
        case 2:
          if (this.formTextArea.label === "") {
            error = true;
            message = "ラベルのフィールドは必須です。";
          } else {
            form = this.formTextArea;
            this.formTextArea = { label: "", value: "" };
          }
          break;
        case 3:
          this.formSelect.options = this.formSelect.options.map((item) => ({
            text: item.text,
            value: item.text,
          }));
          if (this.formSelect.label === "") {
            error = true;
            message = "ラベルのフィールドは必須です。";
          } else if (
            this.formSelect.options.filter((item) => item.text === "").length >
            0
          ) {
            error = true;
            message = "テキストのフィールドは必須です。";
          } else if (
            this.formSelect.options.filter((item) => item.value === "").length >
            0
          ) {
            error = true;
            message = "値のフィールドは必須です。";
          } else if (this.checkValue(this.formSelect)) {
            error = true;
            message = "重複値";
          } else {
            form = this.formSelect;
            this.formSelect = {
              label: "",
              options: [
                {
                  value: "",
                  text: "",
                },
              ],
              value: "",
            };
          }
          break;
        case 4:
          this.formRadio.options = this.formRadio.options.map((item) => ({
            text: item.text,
            value: item.text,
          }));
          this.formRadio.name = this.formRadio.label;
          if (this.formRadio.label === "") {
            error = true;
            message = "ラベルのフィールドは必須です。";
          } else if (this.formRadio.name === "") {
            error = true;
            message = "名前のフィールドは必須です。";
          } else if (
            this.formRadio.options.filter((item) => item.text === "").length > 0
          ) {
            error = true;
            message = "テキストのフィールドは必須です。";
          } else if (
            this.formRadio.options.filter((item) => item.value === "").length >
            0
          ) {
            error = true;
            message = "値のフィールドは必須です。";
          } else if (this.checkValue(this.formRadio)) {
            error = true;
            message = "重複値";
          } else if (this.checkName(this.formRadio)) {
            error = true;
            message = "名前重複";
          } else {
            form = this.formRadio;
            this.formRadio = {
              label: "",
              options: [
                {
                  value: "",
                  text: "",
                },
              ],
              name: "",
              value: "",
            };
          }
          break;
        case 5:
          this.formCheckbox.options = this.formCheckbox.options.map((item) => ({
            text: item.text,
            value: item.text,
          }));
          this.formCheckbox.name = this.formCheckbox.label;
          if (this.formCheckbox.label === "") {
            error = true;
            message = "ラベルのフィールドは必須です。";
          } else if (this.formCheckbox.name === "") {
            error = true;
            message = "名前のフィールドは必須です。";
          } else if (
            this.formCheckbox.options.filter((item) => item.text === "")
              .length > 0
          ) {
            error = true;
            message = "テキストのフィールドは必須です。";
          } else if (
            this.formCheckbox.options.filter((item) => item.value === "")
              .length > 0
          ) {
            error = true;
            message = "値のフィールドは必須です。";
          } else if (this.checkValue(this.formCheckbox)) {
            error = true;
            message = "重複値";
          } else if (this.checkName(this.formCheckbox)) {
            error = true;
            message = "名前重複";
          } else {
            form = this.formCheckbox;
            this.formCheckbox = {
              label: "",
              options: [
                {
                  value: "",
                  text: "",
                },
              ],
              name: "",
              value: [],
            };
          }
          break;
      }
      if (!error) {
        this.form.push({
          type: this.typeForm,
          form: form,
        });
        this.$bvModal.hide("modal-add-form");
      } else {
        this.$toasted.error(message);
      }
    },
    checkValue(form) {
      let error = false;
      form.options.forEach((element, index) => {
        if (
          form.options.filter(
            (item, ind) => item.value == element.value && index != ind
          ).length > 0
        ) {
          error = true;
        }
      });
      return error;
    },
    checkName(form) {
      let error = false;
      if (this.form.length > 0) {
        if (
          this.form.filter(
            (item) => item.form.name && item.form.name == form.name
          ).length > 0
        ) {
          error = true;
        }
      }
      return error;
    },
    deleteElementForm(index) {
      this.form.splice(index, 1);
    },
    async submit() {
      let form = this.form;
      if (form && form.length) {
        form.forEach((val, index) => {
          if (val.type == 5) {
            form[index].form.value = [];
          } else {
            form[index].form.value = "";
          }
        });
      }
      const formData = {
        shop_id: this.shop_id,
        column: this.form,
      };
      await this.$store.dispatch("createInquiry", formData).then((response) => {
        if (response.success == true) {
          this.$router.push({
            name: this.$route.params.shopId
              ? "list inquiry"
              : "list inquiry domain",
          });
        }
      });
    },
    goToListInquiry() {
      this.$router.push({
        name: "list inquiry",
      });
    },
  },
};
</script>
<style lang="scss">
.create-inquiry {
  div {
    div {
      div {
        label {
          background: #d5d5d5;
          height: 40px;
          align-items: center;
          display: flex;
        }
      }
      label {
        background: #d5d5d5;
        height: 40px;
        align-items: center;
        display: flex;
      }
    }
  }
}
.required {
  color: #fff;
  background: red;
  padding: 2px 8px;
  border-radius: 5px;
  top: 0;
  margin-left: 15px;
  font-size: 11px;
}
.custom-control-label {
  background-color: #fff !important;
  height: 20px !important;
}
.create-inquiry input,
.create-inquiry textarea {
  pointer-events: none;
  background: #f3f3f3;
}
</style>
